import { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, useField, useFormikContext } from 'formik';
import { AsyncDropdown, SelectDropdown } from 'shared/components';
import { useCallback } from 'react';
import { fetchGenres, getAgeGroups, searchBook } from 'services';
import { useOptionLoader } from 'shared/hooks';
import AsyncSelect from 'react-select/async';
import { cloneDeep, debounce } from 'lodash';
import { routeConstants } from 'shared/constants';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CustomOptionItem = ({ data, innerRef, innerProps }) => {
  return (
    <div {...innerProps} ref={innerRef} className="quick-search-item">
      {data.resultType === 'Book' ? (
        <a
          href={`/book-details/${data.bookId}`}
          style={{ color: '#000000', textDecoration: 'none' }}
        >
          {data.label}
        </a>
      ) : (
        data.label
      )}
      <span className="type">{data.resultType}</span>
    </div>
  );
};
function AsyncSelectField({
  label,
  onEnter,
  setIsSearch,
  toBeDisplay,
  setToBeDisplay,
  onClearText,
  ...props
}) {
  const [field, meta, helpers] = useField(props.field.name);
  const { setValue } = helpers;
  const [inputValue, setInputValue] = useState('');
  const formik = useFormikContext();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const customBehaviour = (event) => {
    if (event.keyCode === 13 || event.keyCode === 91) {
      // Customize Enter key behavior here
      // For example, prevent default behavior:
      event.preventDefault();
      // Or trigger a specific action:
      // YourActionFunction();
      console.log('Enter key pressed', field, inputValue);
      // formik.submitForm();
      const updatedValues = cloneDeep(formik.values);
      updatedValues.search = inputValue;
      onEnter(updatedValues);
      setIsDropdownOpen(false);
    }
  };

  const handleOnSearch = (string, callback) => {
    if (!string) return;
    setIsDropdownOpen(true);

    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.

    if (string.length > 0) {
      setIsSearch(true);
    }

    if (!string || string.length < 3) return callback([]);
    // try {
    searchBook({ search: string }).then((result) => {
      const allItems = [];
      result.books.forEach((book) => {
        allItems.push({
          value: book._id,
          label: book._id,
          resultType: 'Book',
          bookId: book.bookId,
        });
      });
      result.authors.forEach((author) => {
        allItems.push({
          value: author,
          label: author,
          resultType: 'Author',
        });
      });
      result.tags.forEach((tag) => {
        allItems.push({
          value: tag,
          label: tag,
          resultType: 'Tag',
        });
      });
      callback(allItems);
    });
  };

  const handleClearTextPressed = () => {
    if (inputValue) {
      setInputValue('');
    } else {
      onClearText();
    }
  };

  return (
    <div>
      <AsyncSelect
        cacheOptions
        loadOptions={(str, cb) => {
          debounce(handleOnSearch, 200)(str, cb);
        }}
        menuIsOpen={isDropdownOpen}
        defaultOptions
        value={field.value || ''}
        onChange={(value) => {
          setValue(value);
          setIsDropdownOpen(false);
        }}
        onInputChange={(value) => {
          setInputValue(value);
        }}
        onKeyDown={customBehaviour}
        onBlur={() => {
          helpers.setTouched(true);
          setIsDropdownOpen(false);
        }}
        components={{
          DropdownIndicator: () => (
            <span>
              <img
                src={require('assets/images/icons/search.png').default}
                alt="search"
              />
            </span>
          ),
          NoOptionsMessage: () => (
            <div className="async-search-no-result">Please start typing..</div>
          ),
          Option: CustomOptionItem,
        }}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
      {inputValue || field.value ? (
        <span className="visibleit" onClick={handleClearTextPressed}>
          x
        </span>
      ) : null}
    </div>
  );
}

const BookFilterTop = ({ onSearchClicked, initialData }) => {
  const formik = useRef(null);
  const [ageGroupOptions, setAgeOptions] = useState([]);
  const [genreOptions, loadGenreOptions] = useOptionLoader(fetchGenres);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const overlayRef = useRef(null);
  const [selected, setSelected] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [toBeDisplay, setToBeDisplay] = useState(false);
  const history = useHistory();
  const authUser = useSelector((state) => state.auth.user);
  const [tobeRedirect, setTobeRedirect] = useState(false);

  useEffect(() => {
    if (selected) {
      initialData.search = selected.value;
      setIsOverlayVisible(false);
      setIsSearch(true);
      setToBeDisplay(true);
    } else {
      setToBeDisplay(false);
    }
    if (initialData.search) {
      setIsSearch(true);
      setToBeDisplay(true);
    } else {
      setToBeDisplay(false);
    }
  }, [initialData, selected]);

  const loadAgeGroupOptions = useCallback(async () => {
    const ageGroups = await getAgeGroups({});
    let ageGroupsOptions = [];
    if (!ageGroups.error) {
      ageGroupsOptions = ageGroups.map((genre) => {
        const { _id, ageGroupName, slug } = genre;
        return {
          value: _id,
          label: `${ageGroupName} (${slug} yrs)`,
        };
      });
    }
    setAgeOptions(ageGroupsOptions);
  }, []);

  useEffect(() => {
    loadAgeGroupOptions();
  }, [loadAgeGroupOptions]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        setIsOverlayVisible(false);
      }
    }

    // Add event listener when the overlay is visible
    if (isOverlayVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      // Remove the event listener when the overlay is not visible
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOverlayVisible]);

  useEffect(() => {
    if (authUser?.community?.hideTooloBrand) {
      setTobeRedirect(true);
    }
  }, [authUser]);
  const handleReset = () => {
    formik.current.resetForm({
      values: {
        search: '',
        ageGroup: null,
        genre: null,
        available: false,
      },
    });
    if (tobeRedirect) {
      history.push(`${routeConstants.BROWSE_BOOKS.route}`);
    } else {
      history.push(
        `${routeConstants.SEARCHED_BOOKS.route}?&search=&genre=&ageGroup=`
      );
    }

    window.location.reload();
  };
  const handleClearText = () => {
    formik.current.setFieldValue('search', '');
    const params = new URL(document.location.toString()).searchParams;
    params.set('search', '');
    window.history.pushState({}, '', `?${params.toString()}`);
  };

  return (
    <section className="filterzone">
      <div className="body-container">
        <h1 className="pageheading">Browse Books</h1>
        <div className="filterfldsgroup">
          {ageGroupOptions.length && genreOptions.length ? (
            <Formik
              enableReinitialize
              innerRef={formik}
              initialValues={{
                search: initialData.search
                  ? {
                      value: initialData.search,
                      label: initialData.search,
                    }
                  : '',
                ageGroup: ageGroupOptions.find(
                  (option) => option.value === initialData.ageGroup
                ),
                genre: genreOptions.find(
                  (option) => option.value === initialData.genre
                ),
                available: !!initialData.available,
              }}
              onSubmit={(values, { setSubmitting }) => {
                const updatedValues = cloneDeep(values);
                updatedValues.search = updatedValues.search.value || '';
                onSearchClicked(updatedValues);
                setSubmitting(false);
              }}
            >
              {
                <>
                  {isOverlayVisible && (
                    <div className="overlay-container" ref={overlayRef}>
                      <div className="overlay-content"></div>
                    </div>
                  )}

                  <Form>
                    <div className="flex-row align-items-end" id="desktopsearh">
                      <div className="leftboxx">
                        <div className="one-half">
                          <div className="eachfieldzone">
                            <div className="filter-fldrw">
                              <SelectDropdown
                                name="ageGroup"
                                className="form-control"
                                placeHolder="Age groups"
                                options={ageGroupOptions}
                                selectObjectValue
                                defaultValue={
                                  initialData.ageGroup
                                    ? { ...initialData.ageGroup }
                                    : false
                                }
                                controlled
                              />
                              <span className="forselect">
                                <img
                                  src={
                                    require('assets/images/icons/select.png')
                                      .default
                                  }
                                  alt="select"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="one-half">
                          <div className="eachfieldzone">
                            <div className="filter-fldrw">
                              <AsyncDropdown
                                name="genre"
                                placeHolder="Favourite Genres"
                                loadOptions={loadGenreOptions}
                                selectObjectValue
                                defaultOptions={genreOptions}
                                defaultValue={initialData.genre}
                                controlled
                              />
                              <span className="forselect">
                                <img
                                  src={
                                    require('assets/images/icons/select.png')
                                      .default
                                  }
                                  alt="select"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="clearfix" />
                        <div
                          className="twelve-full"
                          style={{ position: 'relative' }}
                        >
                          <div className="eachfieldzone">
                            <div className="filter-search-auto-complete">
                              <Field
                                component={AsyncSelectField}
                                placeholder="Search by Title, Author, Tags"
                                name="search"
                                classNamePrefix="toolo-select searchinp"
                                onEnter={(e) => {
                                  onSearchClicked(e);
                                  setIsOverlayVisible(false);
                                }}
                                setIsSearch={setIsSearch}
                                toBeDisplay={toBeDisplay}
                                setToBeDisplay={setToBeDisplay}
                                onClearText={handleClearText}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="rightboxx">
                        <div className="twelve-full text-center m-0">
                          <div className="form-check">
                            <Field
                              type="checkbox"
                              name="available"
                              className="form-check-input"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleCheck1"
                            >
                              Show only available books
                            </label>
                          </div>
                        </div>
                        <div className="clearfix" />
                        <div className="twelve-full text-center">
                          <button
                            type="submit"
                            className="primarybtn"
                            style={{ margin: '2px 0' }}
                          >
                            Search
                          </button>
                          <button
                            type="button"
                            className="resetbtn"
                            onClick={handleReset}
                          >
                            Clear Search
                          </button>
                        </div>
                      </div>
                    </div>
                    <div id="mobilesearch">
                      <div className="row">
                        <div className="one-half" style={{ marginTop: '5px' }}>
                          <div className="eachfieldzone">
                            <div className="filter-fldrw">
                              <SelectDropdown
                                name="ageGroup"
                                className="form-control"
                                placeHolder="Age groups"
                                options={ageGroupOptions}
                                selectObjectValue
                                defaultValue={
                                  initialData.ageGroup
                                    ? { ...initialData.ageGroup }
                                    : false
                                }
                                controlled
                              />
                              <span className="forselect">
                                <img
                                  src={
                                    require('assets/images/icons/select.png')
                                      .default
                                  }
                                  alt="select"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="one-half" style={{ marginTop: '5px' }}>
                          <div className="eachfieldzone">
                            <div className="filter-fldrw">
                              <AsyncDropdown
                                name="genre"
                                placeHolder="Favourite Genres"
                                loadOptions={loadGenreOptions}
                                selectObjectValue
                                defaultOptions={genreOptions}
                                defaultValue={initialData.genre}
                                controlled
                              />
                              <span className="forselect">
                                <img
                                  src={
                                    require('assets/images/icons/select.png')
                                      .default
                                  }
                                  alt="select"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="clearfix" />
                        <div
                          className="mobile-input-select"
                          style={{ position: 'relative', marginTop: '5px' }}
                        >
                          <div className="eachfieldzone">
                            <div className="filter-search-auto-complete">
                              <Field
                                component={AsyncSelectField}
                                placeholder="Search by Title, Author, Tags"
                                name="search"
                                classNamePrefix="toolo-select searchinp"
                                onEnter={(e) => {
                                  onSearchClicked(e);
                                  setIsOverlayVisible(false);
                                }}
                                setIsSearch={setIsSearch}
                                onClearText={handleClearText}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mobile-searchbtnn">
                          <button type="submit" className="primarybtn">
                            Search
                          </button>
                        </div>
                        <div className="clearfix" />
                        <div
                          style={{
                            textAlign: 'right',
                            width: '100%',
                            padding: '0px 5px',
                          }}
                        >
                          <button
                            type="button"
                            className="resetbtn"
                            onClick={handleReset}
                          >
                            Clear Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </>
              }
            </Formik>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default BookFilterTop;
