import axios from 'axios';
import * as apiURL from './apiConfig';
import qs from 'query-string';
import { createFilterURL } from 'shared/utils';

export const getBookRibbons = async () => {
  try {
    const response = await axios.get(apiURL.BOOK_RIBBONS_URL);
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const getRibbonBooks = async ({ key, page, filter }) => {
  try {
    const response = await axios.get(
      `${apiURL.BOOK_RIBBONS_URL}/${key}?page=${page}&ageGroup=${
        filter?.ageGroup?.value !== undefined ? filter?.ageGroup?.value : ''
      }&genre=${filter?.genre?.value !== undefined ? filter?.genre?.value : ''}`
    );

    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const getBookDetails = async (id) => {
  try {
    const response = await axios.get(`${apiURL.BOOKS_URL}/${id}`);
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const browseBooks = async (data) => {
  try {
    const response = await axios.get(
      `${apiURL.BROWSE_BOOKS}?${qs.stringify(data)}`
    );
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const getHomeRibbons = async () => {
  try {
    const response = await axios.get(apiURL.HOME_RIBBONS_URL);
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const getRelatedBooks = async (bookId) => {
  try {
    const response = await axios.get(
      `${apiURL.BOOKS_URL}/${bookId}/related-books`
    );
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const getPreviewRibbons = async ({ key, ...restProps }) => {
  try {
    const response = await axios.get(
      `${apiURL.LIBRARY_PREVIEW_URL}/${key}?${qs.stringify(restProps)}`
    );
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const getCommunityDetails = async (communityId) => {
  try {
    const response = await axios.get(
      `${apiURL.COMMUNITY_DETAILS_URL}/${communityId}`
    );
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const searchBook = async ({ search = '' }) => {
  try {
    const response = await axios.get(apiURL.BOOK_SEARCH_URL, {
      params: { search },
    });
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};
