import { useField } from 'formik';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import selectImage from 'assets/images/icons/select.png';
import './AsyncDropdown.css';
import { Spinner } from 'react-bootstrap';
import { useEffect } from 'react';

const Input = ({ autoComplete, ...props }) => (
  <components.Input {...props} autoComplete="new-place" />
);

const LoadingIndicator = (props) => {
  return <Spinner variant="primary" animation="border" size="sm" {...props} />;
};

const DropdownIndicator = ({ indicatorImage, ...props }) => {
  return (
    <components.DropdownIndicator {...props}>
      <img
        src={indicatorImage || selectImage}
        alt="location"
        className="toolo-select__indicator__image"
      />
    </components.DropdownIndicator>
  );
};

const AsyncDropdown = ({ label, ...props }) => {
  // This isn't an input, so instead of using the values in 'field' directly,
  // we'll use 'meta' and 'helpers'.

  const [field, meta, helpers] = useField(props);

  const {
    cityDropDown,
    loadOptions,
    placeHolder,
    onOptionSelected,
    disabled,
    defaultOptions,
    indicatorImage,
    defaultValue,
    selectObjectValue,
    controlled,
    reset,
    isMulti = false,
  } = props;
  const { setValue } = helpers;
  useEffect(() => {
    if (reset) {
      setValue({});
    }
  }, [reset]);

  const handleOnChange = (option) => {
    if (isMulti) {
      setValue(option.map((optionData) => optionData.value));
    } else {
      setValue(selectObjectValue ? option : option.value);
    }
    if (onOptionSelected) {
      //meta is passed here to avoid 'variable not used' warning
      onOptionSelected(option, meta);
    }
  };

  if (controlled !== undefined) {
    return (
      <AsyncSelect
        loadOptions={loadOptions}
        name={field.name}
        isDisabled={disabled}
        defaultOptions={defaultOptions || ''}
        onChange={(option) => handleOnChange(option)}
        placeholder={placeHolder}
        aria-label={placeHolder}
        noOptionsMessage={() => 'Please start typing'}
        inputProps={{ autoComplete: 'new-place' }}
        classNamePrefix="toolo-select"
        defaultValue={defaultValue || null}
        value={field.value}
        isMulti={isMulti}
        components={{
          IndicatorSeparator: () => null,
          Input,
          DropdownIndicator: (props) => (
            <DropdownIndicator {...props} indicatorImage={indicatorImage} />
          ),
          LoadingIndicator: (props) => <LoadingIndicator {...props} />,
        }}
        maxMenuHeight={200}
      />
    );
  } else if (cityDropDown !== undefined) {
    return (
      <AsyncSelect
        loadOptions={loadOptions}
        name={field.name}
        isDisabled={disabled}
        defaultOptions={defaultOptions || false}
        onChange={(option) => handleOnChange(option)}
        placeholder={placeHolder}
        aria-label={placeHolder}
        noOptionsMessage={() => 'Please start typing'}
        inputProps={{ autoComplete: 'new-place' }}
        classNamePrefix="toolo-select"
        // defaultValue={defaultValue || null}
        // value={defaultOptions && defaultOptions[0]}
        isMulti={isMulti}
        components={{
          IndicatorSeparator: () => null,
          Input,
          DropdownIndicator: (props) => (
            <DropdownIndicator {...props} indicatorImage={indicatorImage} />
          ),
          LoadingIndicator: (props) => <LoadingIndicator {...props} />,
        }}
        maxMenuHeight={200}
      />
    );
  } else {
    return (
      <AsyncSelect
        loadOptions={loadOptions}
        name={field.name}
        isDisabled={disabled}
        defaultOptions={defaultOptions || false}
        onChange={(option) => handleOnChange(option)}
        placeholder={placeHolder}
        aria-label={placeHolder}
        noOptionsMessage={() => 'Please start typing'}
        inputProps={{ autoComplete: 'new-place' }}
        classNamePrefix="toolo-select"
        // defaultValue={defaultValue || null}
        // value={defaultOptions && defaultOptions[0]}
        isMulti={isMulti}
        components={{
          IndicatorSeparator: () => null,
          Input,
          DropdownIndicator: (props) => (
            <DropdownIndicator {...props} indicatorImage={indicatorImage} />
          ),
          LoadingIndicator: (props) => <LoadingIndicator {...props} />,
        }}
        maxMenuHeight={200}
      />
    );
  }
};

export default AsyncDropdown;
