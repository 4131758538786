import { DashboardSlider, ImageWithLoading } from 'shared/components';
import { imageConfigs } from 'shared/constants';
const { IMAGE_URL } = imageConfigs;

const DashboardRibbon = ({ childData, userProfileData }) => {
  const { childName, books, profileImage } = childData;
  return (
    <>
      <div className="dash-foruser">
        <span>
          <ImageWithLoading
            src={`${IMAGE_URL}/${profileImage}`}
            alt="username"
            className="img-responsive"
          />
        </span>
        {childName}
      </div>
      <DashboardSlider books={books} userProfileData={userProfileData} />
    </>
  );
};

export default DashboardRibbon;
