import React from 'react';
import routeConstants from 'shared/constants/routes';
const PreSchoolPillars = React.lazy(() => import('./'));

const PreSchoolPillarsRoute = {
  path: routeConstants.PRESCHOOL_PILLARS.route,
  component: PreSchoolPillars,
};

export default PreSchoolPillarsRoute;
