export const eventSlider = {
  autoplay: true,
  autoplaySpeed: 3000,
  //centerMode:true,
  slidesToShow: 2.5,
  slidesToScroll: 2.5,
  initialSlide: 0,
  infinite: false,
  dots: false,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
        dots: false,
      },
    },
    {
      breakpoint: 1390,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 780,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

// Slick Slider - Borrowed Books
export const borrowedBooks = {
  arrows: true,
  //centerMode:true,
  slidesToShow: 7,
  slidesToScroll: 1,
  initialSlide: 0,
  infinite: false,
  dots: false,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 1,
        dots: false,
      },
    },
    {
      breakpoint: 1390,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 780,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

//slider fot home page book ribon
export const mostBorrowedHome = {
  arrows: true,
  autoplay: true,
  autoplaySpeed: 2000,
  //centerMode:true,
  slidesToShow: 7,
  slidesToScroll: 1,
  initialSlide: 0,
  infinite: true,
  dots: false,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 1,
        dots: false,
      },
    },
    {
      breakpoint: 1390,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1250,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 780,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

// Slick Slider - Indian Books
export const indianBooks = {
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: true,
  //centerMode:true,
  slidesToShow: 7,
  slidesToScroll: 2,
  initialSlide: 0,
  infinite: false,
  dots: false,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 3,
        dots: false,
      },
    },
    {
      breakpoint: 1390,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 780,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

export const borrowedDashboardBooks = {
  arrows: true,
  //centerMode:true,
  slidesToShow: 6,
  slidesToScroll: 2,
  initialSlide: 0,
  infinite: false,
  dots: false,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 3,
        dots: false,
      },
    },
    {
      breakpoint: 1390,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 780,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

export const testimonials = {
  autoplay: false,
  autoplaySpeed: 2000,
  arrows: true,
  //centerMode:true,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  infinite: false,
  dots: false,
  responsive: [
    {
      breakpoint: 780,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const homeinfoslide = {
  autoplay: true,
  autoplaySpeed: 8000,
  arrows: true,
  //centerMode:true,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  infinite: true,
  dots: false,
  responsive: [
    {
      breakpoint: 780,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const ambassadornews = {
  autoplay: false,
  autoplaySpeed: 2000,
  arrows: true,
  // centerMode: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  infinite: false,
  dots: false,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 5,
        dots: false,
      },
    },
    {
      breakpoint: 1390,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 780,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

export const preSchoolSlider = {
  arrows: true,
  autoplay: true,
  autoplaySpeed: 3000,
  //centerMode:true,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  infinite: true,
  dots: false,
  responsive: [
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
      },
    },
    {
      breakpoint: 1650,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1250,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplaySpeed: 6000,
      },
    },
    {
      breakpoint: 780,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 6000,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 6000,
      },
    },
  ],
};

export const preschoolFeatures = {
  arrows: false,
  autoplay: true,
  autoplaySpeed: 6000,
  //centerMode:true,
  slidesToShow: 2,
  slidesToScroll: 1,
  initialSlide: 0,
  infinite: true,
  dots: true,
  responsive: [
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1250,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplaySpeed: 10000,
      },
    },
    {
      breakpoint: 780,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 10000,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 10000,
      },
    },
  ],
};
