import { useField } from 'formik';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_orange.css';
import { useEffect, useRef } from 'react';
// export const FormikDatePicker = ({
//   onChange = () => {},
//   maxDate,
//   minDate,
//   ...props
// }) => {
//   const [field, meta, helpers] = useField(props);
//   const { setValue } = helpers;
//   return (
//     <>
//       <Flatpickr
//         placeholder="dd-mm-yyyy"
//         className="newdatepicker-control "
//         name={field.name}
//         value={field.value}
//         onChange={(val) => {
//           setValue(val);
//           onChange(meta);
//         }}
//         calendarClassName="newdatepicker-calender"
//         options={{
//           minDate:
//             minDate ||
//             new Date(new Date().setFullYear(new Date().getFullYear() - 72)),
//           maxDate: maxDate || new Date(),
//           monthSelectorType: 'dropdown',
//           yearSelectorType: 'dropdown',
//           showMonths: 1,
//         }}
//         format="y-MM-dd"
//       />
//     </>
//   );
// };
const FormikDatePicker = ({
  value,
  onChange = () => {},
  maxDate,
  minDate,
  ...props
}) => {
  const fpRef = useRef(null);
  const defaultMinDate = new Date(
    new Date().setFullYear(new Date().getFullYear() - 20)
  );
  const defaultMaxDate = new Date();
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;

  useEffect(() => {
    if (fpRef.current?.flatpickr) {
      const fp = fpRef.current.flatpickr;

      // Create and inject year dropdown when calendar opens
      fp.config.onOpen = [
        ...(fp.config.onOpen || []),
        () => {
          const yearInput = fp.currentYearElement;
          if (!yearInput || yearInput.parentNode.querySelector('select'))
            return;

          // Create year dropdown
          const yearSelect = document.createElement('select');
          yearSelect.className = 'flatpickr-year-select';

          // Calculate year range
          const minYear = fp.config.minDate
            ? fp.config.minDate.getFullYear()
            : defaultMinDate.getFullYear();
          const maxYear = fp.config.maxDate
            ? fp.config.maxDate.getFullYear()
            : defaultMaxDate.getFullYear();

          // Add year options
          for (let year = maxYear; year >= minYear; year--) {
            const option = document.createElement('option');
            option.value = year;
            option.text = year;
            yearSelect.appendChild(option);
          }

          // Set current year
          yearSelect.value = fp.currentYear;

          // Handle year change
          yearSelect.addEventListener('change', (e) => {
            fp.currentYear = parseInt(e.target.value);
            fp.redraw();
          });

          // Replace year input with select
          yearInput.parentNode.replaceChild(yearSelect, yearInput);
        },
      ];
    }
  }, []);

  return (
    <div className="w-full max-w-sm">
      <Flatpickr
        ref={fpRef}
        placeholder="dd-mm-yyyy"
        className="newdatepicker-control "
        name={field.name}
        value={field.value}
        onChange={(val) => {
          setValue(val);
          onChange(meta);
        }}
        calendarClassName="newdatepicker-calender"
        format="y-MM-dd"
        options={{
          minDate:
            minDate ||
            new Date(new Date().setFullYear(new Date().getFullYear() - 72)),
          maxDate: maxDate || new Date(),
        }}
      />
      <style>{`
        .flatpickr-year-select {
          -webkit-appearance: menulist;
          -moz-appearance: menulist;
          appearance: menulist;
          padding: 2px 5px;
          border: none;
          border-radius: 4px;
          background: #ff8a65;
          cursor: pointer;
          margin: 0 2px;
          color:#ffffff;
          outline:none;
          box-shadow:none
        }
          .flatpickr-year-select ~ .arrowUp{ display:none}
          .flatpickr-year-select ~ .arrowDown{ display:none}
      `}</style>
    </div>
  );
};
export default FormikDatePicker;
