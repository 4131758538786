import { Form, Formik } from 'formik';
import { AsyncDropdown, SelectDropdown } from 'shared/components';
import { fetchGenres, getAgeGroups } from 'services';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useOptionLoader } from 'shared/hooks';
import { useHistory } from 'react-router-dom';
import { routeConstants } from 'shared/constants';

const BookFilterSecondary = ({ initialData, bookinformations, paramsObj }) => {
  const formik = useRef(null);
  const [ageGroupOptions, setAgeOptions] = useState([]);
  const [genreOptions, loadGenreOptions] = useOptionLoader(fetchGenres);
  const history = useHistory();
  const { key } = bookinformations;

  const loadAgeGroupOptions = useCallback(async () => {
    const ageGroups = await getAgeGroups({});
    let ageGroupsOptions = [];
    if (!ageGroups.error) {
      ageGroupsOptions = ageGroups.map((oneAgeBlock) => {
        const { _id, ageGroupName, slug } = oneAgeBlock;
        return {
          value: _id,
          label: `${ageGroupName} (${slug} yrs)`,
        };
      });
    }
    setAgeOptions(ageGroupsOptions);
  }, []);

  useEffect(() => {
    loadAgeGroupOptions();
  }, [loadAgeGroupOptions]);

  const handleReset = () => {
    formik.current.resetForm({
      values: {
        ageGroup: initialData.ageGroup || null,
        genre: initialData.genre || null,
      },
    });
    history.push(`${routeConstants.BROWSE_BOOKS.route}/ribbon/${key}`);
    window.location.reload();
  };
  const handleSearch = (values) => {
    const { genre, ageGroup } = values;
    const params = `?genre=${genre ? genre.value : ''}&ageGroup=${
      ageGroup ? ageGroup.value : ''
    }`;
    history.push(`${routeConstants.BROWSE_BOOKS.route}/ribbon/${key}${params}`);
  };

  return (
    <>
      <section className="secondary__filter__zone">
        <div className="body-container">
          <div className="filterfldsgroup secondary__search__area">
            <Formik
              enableReinitialize
              innerRef={formik}
              initialValues={{
                ageGroup: ageGroupOptions.find(
                  (option) => option.value === paramsObj?.ageGroup?.value
                ),
                genre: genreOptions.find(
                  (option) => option.value === paramsObj?.genre.value
                ),
              }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                handleSearch(values);
              }}
            >
              <Form>
                <div className="row">
                  <div className="twelve-full">
                    <h4 className="text-center">Filter With</h4>
                  </div>
                  <div className="clearfix" />
                  <div className="one-half">
                    <div className="filter-fldrw">
                      <SelectDropdown
                        name="ageGroup"
                        className="form-control"
                        placeHolder="Age groups"
                        options={ageGroupOptions}
                        selectObjectValue
                        defaultValue={
                          initialData.ageGroup
                            ? { ...initialData.ageGroup }
                            : null
                        }
                        controlled
                      />
                      <span className="forselect">
                        <img
                          src={
                            require('assets/images/icons/select.png').default
                          }
                          alt="select"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="one-half">
                    <div className="filter-fldrw">
                      <AsyncDropdown
                        name="genre"
                        placeHolder="Favourite Genres"
                        loadOptions={loadGenreOptions}
                        selectObjectValue
                        defaultOptions={genreOptions}
                        defaultValue={initialData.genre || null}
                        controlled
                      />
                      <span className="forselect">
                        <img
                          src={
                            require('assets/images/icons/select.png').default
                          }
                          alt="select"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="twelve-full">
                    <div className="secondary__filter__buttons">
                      <button
                        type="submit"
                        className="primarybtn"
                        style={{ margin: '2px 0' }}
                      >
                        Search
                      </button>
                      <button
                        type="button"
                        className="resetbtn  reset__secondary"
                        onClick={handleReset}
                      >
                        RESET FILTER
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </section>
    </>
  );
};

export default BookFilterSecondary;
