import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BookSliderItem } from 'shared/components';
import BookFilterSecondary from '../BookFilterSecondary/BookFilterSecondary';

const BookList = ({ bookItems, searchResults, paramsObj }) => {
  const { title, books } = bookItems;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchVal = queryParams.get('search');
  const genreVal = queryParams.get('genre');
  const ageGroupVal = queryParams.get('ageGroup');
  const [defMessage, setDefMessage] = useState('');
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDefMessage('There are currently 0 results for your search.');
    }, 1500);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="body-container">
      {title === undefined ? null : (
        <div
          className={`zoneheadingbox ${
            !searchResults
              ? 'justify__center__padding20'
              : 'justify__center__nopadding'
          }`}
        >
          <div className={`sectionheader ${!searchResults ? 'mb10' : ''}`}>
            {searchResults
              ? searchVal === '' && genreVal === '' && ageGroupVal === ''
                ? ''
                : 'Search Result : '
              : `Books : ${title}`}
          </div>
        </div>
      )}

      {!searchResults && (
        <BookFilterSecondary
          initialData={{}}
          bookinformations={bookItems}
          paramsObj={paramsObj}
        />
      )}

      <div className="aftersearchlist">
        {books && Array.isArray(books) && books.length ? (
          books.map((bookItem) => {
            return <BookSliderItem bookData={bookItem} key={bookItem.bookId} />;
          })
        ) : (
          <h5 className="no-message mt-1 min-150">
            {searchVal === '' && genreVal === '' && ageGroupVal === ''
              ? ''
              : defMessage}
          </h5>
        )}
      </div>
    </div>
  );
};

export default BookList;
